import useCssUtil from "@hooks/use-css-util"
import { Container, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import GoToAppButton from "./GoToAppButton"
import StepBlock from "./StepBlock"

import Step1Icon from "@svg/upload-icon.svg"
import Step2Icon from "@svg/files-icon.svg"
import Step3Icon from "@svg/credit-card-icon.svg"
import Step4Icon from "@svg/ramp-box-icon.svg"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    backgroundColor: theme.custom.lightSectionBgColor,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  stepContainer: {
    marginTop: 50,
    marginBottom: 50,
    [theme.breakpoints.up("sm")]: {
      marginTop: 80,
      marginBottom: 80,
    },
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "center",
  },
  description: {
    fontSize: "18px",
  },
}))

export default function HowItWorks() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.container}>
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={cssUtil.mb20}
        >
          <FormattedMessage
            defaultMessage="How it Works"
            id="components.HowItworks.title"
          />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          className={classes.description}
        >
          <FormattedMessage
            defaultMessage="Create your Totenpass in a few easy steps"
            id="components.HowItWorks.description"
          />
        </Typography>
      </Container>

      <Container maxWidth="xl" className={classes.stepContainer}>
        <Grid container spacing={8}>
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Select a drive size and upload your digital files."
                id="components.StepBlock.stepOneDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 1"
                id="components.StepBlock.stepOneTitle"
              />
            }
            icon={<Step1Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Customize your Totenpass by arranging or editing your files before creation"
                id="components.StepBlock.stepTwoDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 2"
                id="components.StepBlock.stepTwoTitle"
              />
            }
            icon={<Step2Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Confirm your drive settings and pay with a credit card or cryptocurrency"
                id="components.StepBlock.stepThreeDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 3"
                id="components.StepBlock.stepThreeTitle"
              />
            }
            icon={<Step3Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={3}
            content={
              <FormattedMessage
                defaultMessage="Receive your Totenpass within 3 business days"
                id="components.StepBlock.stepFourDescription"
              />
            }
            title={
              <FormattedMessage
                defaultMessage="Step 4"
                id="components.StepBlock.stepFourTitle"
              />
            }
            icon={<Step4Icon />}
          />
        </Grid>
      </Container>

      <Container maxWidth="sm" className={classes.ctaContainer}>
        <GoToAppButton variant="contained" color="primary" size="large">
          <FormattedMessage
            defaultMessage="Get Started"
            id="components.HowItWorks.cta"
          />
        </GoToAppButton>
      </Container>
    </section>
  )
}
