import React from "react"
import useCssUtil from "@hooks/use-css-util"
import clsx from "clsx"
import { Container, Grid, Typography, Divider, Hidden } from "@material-ui/core"
import { FormattedMessage } from "gatsby-plugin-intl"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import Button from "./Button"
import { GetStartedButton } from "./GetStartedButton"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    paddingTop: theme.custom.sectionSpacingFluidHalf,
    marginBottom: theme.custom.sectionSpacingFluid,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingSm,
      marginBottom: theme.custom.sectionSpacingLg,
    },
  },
  divider: {
    backgroundColor: "#E0DDD7",
  },
  row: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  mobileLabel: {
    width: "100px",
    textAlign: "left",
  },
}))

export default function IntroSection() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <>
      <section className={classes.container}>
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography className={cssUtil.mb8} component="h3" variant="h4">
                <FormattedMessage
                  defaultMessage="Pick Your Own Totenpass"
                  id="components.FeatureComparisonSection.title"
                />
              </Typography>
              <Typography
                className={cssUtil.lgText}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  defaultMessage="We made it easy for you to decide"
                  id="components.FeatureComparisonSection.subTitle"
                />
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Hidden smDown>
                <Typography
                  className={cssUtil.textAlignCenter}
                  component="h4"
                  variant="h4"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                </Typography>
              </Hidden>
            </Grid>
            <Grid item md={4}>
              <Hidden smDown>
                <Typography
                  className={cssUtil.textAlignCenter}
                  component="h4"
                  variant="h4"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                </Typography>
              </Hidden>
            </Grid>
          </Grid>

          <Divider
            className={clsx(cssUtil.mb32, cssUtil.mt20, classes.divider)}
          />

          <Grid container alignItems="center" className={cssUtil.mb32}>
            <Grid item xs={12} md={4}>
              <Typography
                className={cssUtil.lgText}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  defaultMessage="Cost"
                  id="components.FeatureComparisonSection.feat1Label"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="$500 USD"
                  id="components.FeatureComparisonSection.feat1MediumData2"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="$750 USD"
                  id="components.FeatureComparisonSection.feat1LargeData2"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" className={cssUtil.mb32}>
            <Grid item xs={12} md={4}>
              <Typography
                className={cssUtil.lgText}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  defaultMessage="Physical size"
                  id="components.FeatureComparisonSection.feat2Label"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="54mm x 86mm"
                  id="components.FeatureComparisonSection.feat2MediumData"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="64mm x 128mm"
                  id="components.FeatureComparisonSection.feat2LargeData"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" className={cssUtil.mb32}>
            <Grid item xs={12} md={4}>
              <Typography
                className={cssUtil.lgText}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  defaultMessage="Analog storage capacity"
                  id="components.FeatureComparisonSection.feat3Label"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="2,196 Pages"
                  id="components.FeatureComparisonSection.feat3MediumData"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="3,894 Pages"
                  id="components.FeatureComparisonSection.feat3LargeData"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" className={cssUtil.mb32}>
            <Grid item xs={12} md={4}>
              <Typography
                className={cssUtil.lgText}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  defaultMessage="Digital storage capacity"
                  id="components.FeatureComparisonSection.feat4Label"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="62.85 MB"
                  id="components.FeatureComparisonSection.feat4MediumData"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="111.42 MB"
                  id="components.FeatureComparisonSection.feat4LargeData"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" className={cssUtil.mb32}>
            <Grid item xs={12} md={4}>
              <Typography
                className={cssUtil.lgText}
                variant="body1"
                color="textSecondary"
              >
                <FormattedMessage
                  defaultMessage="Pure gold content"
                  id="components.FeatureComparisonSection.feat5Label"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="0.2 grams"
                  id="components.FeatureComparisonSection.feat5MediumData"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(cssUtil.textAlignCenter, classes.row)}
            >
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.lgText, classes.mobileLabel)}
                  variant="body1"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                  :&nbsp;
                </Typography>
              </Hidden>
              <Typography className={cssUtil.lgText} variant="body1">
                <FormattedMessage
                  defaultMessage="0.4 grams"
                  id="components.FeatureComparisonSection.feat5LargeData"
                />
              </Typography>
            </Grid>
          </Grid>

          <Divider className={clsx(cssUtil.mb24, classes.divider)} />

          <Grid container alignItems="center" className={cssUtil.mb32}>
            <Hidden smDown>
              <Grid item md={4}>
                &nbsp;
              </Grid>
            </Hidden>
            <Grid item xs={6} md={4} className={cssUtil.textAlignCenter}>
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.textAlignCenter, cssUtil.mb20)}
                  component="h4"
                  variant="h4"
                >
                  <FormattedMessage
                    defaultMessage="Medium"
                    id="components.FeatureComparisonSection.mediumTitle"
                  />
                </Typography>
              </Hidden>
              <GetStartedButton />
            </Grid>
            <Grid item xs={6} md={4} className={cssUtil.textAlignCenter}>
              <Hidden mdUp>
                <Typography
                  className={clsx(cssUtil.textAlignCenter, cssUtil.mb20)}
                  component="h4"
                  variant="h4"
                >
                  <FormattedMessage
                    defaultMessage="Large"
                    id="components.FeatureComparisonSection.largeTitle"
                  />
                </Typography>
              </Hidden>
              <GetStartedButton />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}
