import useCssUtil from "@hooks/use-css-util"
import { Button, Container, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import classNames from "classnames"
import clsx from "clsx"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import GoToAppButton from "./GoToAppButton"
import { HeroCarousel } from "./herocarousel"
import { Video } from "./Video"

const useCss = makeStyles((theme: CustomTheme) => ({
  hero: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    paddingTop: theme.custom.desktopHeaderHeight,
    marginBottom: theme.custom.sectionSpacingFluid,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "calc(100% - 150px)",
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: "url(/images/pattern-bg.svg)",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("sm")]: {
      "&::after": {
        height: "calc(100% - 200px)",
      },
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.custom.sectionSpacingSm,
    },
  },
  heroContentContainer: {
    position: "relative",
    zIndex: 1,
    marginBottom: theme.custom.sectionSpacingSm,
    [theme.breakpoints.up("md")]: {
      paddingTop: "80px",
    },
  },
  heroTitle: {
    color: theme.custom.lightHeadingTextColor,
  },
  heroDescription: {
    fontSize: "20px",
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "center",
  },
  ctaButton: {
    marginLeft: 15,
    marginRight: 15,
  },
  heroGraphicContainer: {
    position: "relative",
    zIndex: 1,
  },
  graphic: {
    width: "100%",
    margin: "0 auto",
    boxShadow: "0 30px 60px 0 rgba(89, 69, 16, .4)",
    borderRadius: "44px",
    [theme.breakpoints.up("sm")]: {
      width: 560,
    },
  },
}))

export default function Hero() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.hero}>
      <Container maxWidth="md" className={classes.heroContentContainer}>
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={classNames(cssUtil.mb20, classes.heroTitle)}
        >
          <FormattedMessage
            defaultMessage="The New Paradigm For The Permanent Storage of Precious Digital Data"
            id="components.Hero.title"
          />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={clsx(cssUtil.mb30, classes.heroDescription)}
        >
          <FormattedMessage
            defaultMessage="Totenpass is a permanent digital storage drive made of solid gold. End your dependence on the cloud, the internet, energy sources, and destructible hardware."
            id="components.Hero.description"
          />
        </Typography>
        <div className={classes.ctaContainer}>
          <GoToAppButton
            variant="contained"
            color="primary"
            className={classes.ctaButton}
          >
            <FormattedMessage
              defaultMessage="Get Started"
              id="components.Hero.cta.left"
            />
          </GoToAppButton>
          <Video />
        </div>
      </Container>

      <Container maxWidth="sm" className={classes.heroGraphicContainer}>
        <HeroCarousel />
      </Container>
    </section>
  )
}
