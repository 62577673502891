import Hero from "@components/Hero"
import HowItWorks from "@components/HowItWorks"
import IntroSection from "@components/IntroSection"
import KeyFeatures from "@components/KeyFeatures"
import FeatureComparisonSection from "@components/FeatureComparisonSection"
import ReaderSection from "@components/ReaderSection"
import DecryptSection from "@components/DecryptSection"
import TPvsOthers from "@components/TPvsOthers"
import Reviews from "@components/Reviews"
import Contact from "@components/contact"
import Seo from "@components/Seo"
import * as routes from "@routes"
import { defineMessages, useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import { useAppURL } from "@hooks/use-app-url"
import { PageProps } from "gatsby"
import { ReferralDialog } from "@components/ReferralDialog"

const messages = defineMessages({
  description: {
    defaultMessage:
      "Totenpass is a permanent digital storage drive made of solid gold. End your dependence on the cloud, the internet, energy sources, and destructible hardware. Preserve your precious data once and forever.",
    id: "pages.Index.description",
  },
  title: {
    defaultMessage: "Home",
    id: "pages.Index.title",
  },
})

const createIdSection = (route: string) => <div id={route.replace("/#", "")} />

export default function IndexPage({ location }: PageProps) {
  const intl = useIntl()
  const [didmount, setdidmount] = useState(false)

  useEffect(() => {
    if (!didmount && typeof window !== "undefined") {
      setdidmount(true)
    }
  }, [didmount, typeof window])

  return (
    <>
      <Seo
        description={intl.formatMessage(messages.description)}
        title={intl.formatMessage(messages.title)}
      />
      <Hero />
      {createIdSection(routes.about)}
      <IntroSection />
      {createIdSection(routes.howItWorks)}
      <HowItWorks />
      {createIdSection(routes.features)}
      <KeyFeatures />
      <FeatureComparisonSection />
      <ReaderSection />
      <DecryptSection />
      <TPvsOthers />
      <Reviews />
      <Contact />
      {createIdSection(routes.contact)}
      <ReferralDialog search={location.search} />
    </>
  )
}
