import { useEffect, useState } from "react"

export const usemount = () => {
  const [didmount, setdidmount] = useState(false)

  useEffect(() => {
    setdidmount(true)
  }, [])

  return didmount
}
