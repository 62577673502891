import React from "react"
import useCssUtil from "@hooks/use-css-util"
import clsx from "clsx"
import { Container, Typography, Divider } from "@material-ui/core"
import { FormattedMessage } from "gatsby-plugin-intl"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import IntroSectionItem from "./IntroSectionItem"
import Button from "./Button"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    marginBottom: theme.custom.sectionSpacingFluid,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.custom.sectionSpacingLg,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    backgroundColor: "#E0DDD7",
  },
}))

export default function IntroSection() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <>
      <section className={classes.container}>
        <Container maxWidth="lg">
          <Typography className={cssUtil.mb20} component="h2" variant="h1">
            <FormattedMessage
              defaultMessage="Preserve Your Precious Data Once and Forever"
              id="components.IntroSection.title"
            />
          </Typography>
          <IntroSectionItem>
            <FormattedMessage
              defaultMessage="Totenpass empowers you to store your precious and personal information: family photos, important documents, intellectual property, or any other digital file. Our revolutionary storage drive helps you to retain control of your data while reducing your dependence on third parties and the Internet, where these files can be easily copied or stolen. We invite you to make your personal history into a tangible and everlasting treasure."
              id="components.IntroSection.paragraph1"
            />
          </IntroSectionItem>
          <IntroSectionItem>
            <FormattedMessage
              defaultMessage="The Totenpass is a permanent physical storage drive which preserves your digital information. Constructed from the indestructible elements of solid gold and nickel, made in the same labs which revolutionized film photography, the Totenpass will preserve your digital data by way of a patented light-diffraction process which imprints images, documents and other files onto the metal card. A Totenpass can be human readable, without the aid of computers, or machine readable, with the employ of a smartphone. One Totenpass can permanently store up to 111 Megabytes (or 3,894 individual pages) of data, thereby ending any future dependence on energy, the Cloud, or the Internet."
              id="components.IntroSection.paragraph2"
            />
          </IntroSectionItem>
          <Divider
            className={clsx(cssUtil.mb40, cssUtil.mt40, classes.divider)}
          />
          <a
            className={classes.buttonContainer}
            href="/documents/totenpass-whitepaper-january-2021.pdf"
            target="_blank"
            style={{
              textDecoration: `none`,
            }}
          >
            <Button color="primary" variant="contained">
              <FormattedMessage
                defaultMessage="Download White Paper"
                id="components.IntroSection.whitePaperButtonLabel"
              />
            </Button>
          </a>
        </Container>
      </section>
    </>
  )
}
