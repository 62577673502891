import useCssUtil from "@hooks/use-css-util"
import clsx from "clsx"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import React, { ReactNode } from "react"

export interface IIntroSectionItemProps {
  children?: ReactNode
}

const useCss = makeStyles((theme: CustomTheme) => ({
  description: {
    fontSize: "18px",
  },
}))

export default function IntroSectionItem(props: IIntroSectionItemProps) {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <Typography
      className={clsx(cssUtil.mb20, classes.description)}
      color="textSecondary"
      variant="body1"
    >
      {props.children}
    </Typography>
  )
}
