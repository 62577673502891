import { defineMessages, useIntl } from "gatsby-plugin-intl"
import { ReactNode, useEffect, useState } from "react"
import { array } from "tiinvo"
import { usemount } from "./use-mount"
import React from "react"
import useCssUtil from "@hooks/use-css-util"

export interface IKeyFeature {
  summary: ReactNode
  title: ReactNode
  image: string[]
}

function create(
  title: ReactNode,
  summary: ReactNode,
  ...image: string[]
): IKeyFeature {
  return { summary, title, image }
}

const messages = defineMessages({
  title1: {
    defaultMessage: "A Storage Drive Made of Gold and Nickel",
    id: "components.KeyFeature.f1Title",
  },
  summary1: {
    defaultMessage:
      "The Totenpass is built from solid gold and nickel, which inherently resist destruction, never tarnish and will therefore outlive all other ephemeral forms of data storage, from hard drives to Cloud servers. By having your data permanently embodied in these special elements, your precious information is safe from annual decay, natural disasters and online risks. It is also good for a sustainable future, as Cloud services depend upon an obscene amount of electricity while the Totenpass is built once and requires no subsequent energy consumption.",
    id: "components.KeyFeature.f1Description",
  },
  title2: {
    defaultMessage: "Every Totenpass is Unique",
    id: "components.KeyFeature.f2Title",
  },
  summary2: {
    defaultMessage:
      "The Totenpass is like a canvas and you are the painter. You may choose to store only one file, to be displayed and enjoyed with pride of place in your home. Think of your most memorable family photograph, a love letter, or a valuable NFT. Or you may choose to store thousands of documents or photographs to be retrieved at a later date using our proprietary reading technology and smartphone app. We give you the freedom to build the drives as you please, for whatever purpose. Whether you’re a mother, a creative, or a financial or legal professional, you will find the Totenpass to be the one true way that your hard work and cherished memories can be with you for the rest of your life and beyond.",
    id: "components.KeyFeature.f2Description",
  },
  title3: {
    defaultMessage: "We Never See Your Data",
    id: "components.KeyFeature.f3Title",
  },
  summary3: {
    defaultMessage:
      "We have created a one way digital-to-analog conversion process which means that nobody at our facility can see your data and the information that you upload is never stored anywhere, besides on your personal Totenpass. Our technology has been trusted by leading corporations such as SpaceX and even the US Military. For passwords, private keys, or other sensitive symbolic information that you may wish to store, we have developed an {0} total encryption standard which you can read about {1}.",
    id: "components.KeyFeature.f3Description",
  },
  opensource: {
    defaultMessage: "open-source",
    id: "components.KeyFeature.f3Description.open-source",
  },
  here: {
    defaultMessage: "here",
    id: "components.KeyFeature.f3Description.here",
  },
})

const images = ["tp-feat-1.png", "tp-feat-2.png", "tp-feat-3.png"]

const imagesdict = images.reduce(
  (s, c) => ({ ...s, [c]: c }),
  {} as Record<string, string>
)

export default function useKeyFeatures() {
  const intl = useIntl()
  const cssUtil = useCssUtil()
  const [image0, setimage0] = useState(images[0])
  const [image1, setimage1] = useState(images[1])
  const [image2, setimage2] = useState(images[2])
  const fns = {
    setimage0,
    setimage1,
    setimage2,
  }

  useEffect(() => {
    array.mix(images).forEach((image, index) => {
      ;(fns as any)[`setimage${index}`](image)
    })
  }, [usemount()])

  return [
    create(
      intl.formatMessage(messages.title1),
      intl.formatMessage(messages.summary1),
      image0
    ),
    create(
      intl.formatMessage(messages.title2),
      intl.formatMessage(messages.summary2),
      image1
    ),
    create(
      intl.formatMessage(messages.title3),
      intl.formatMessage(messages.summary3, {
        0: (
          <a
            href="https://github.com/totenpass"
            target="_blank"
            rel="noopener"
            className={cssUtil.goldText}
          >
            {intl.formatMessage(messages.opensource)}
          </a>
        ),
        1: (
          <a
            href="https://github.com/totenpass/tes-specs"
            target="_blank"
            rel="noopener"
            className={cssUtil.goldText}
          >
            {intl.formatMessage(messages.here)}
          </a>
        ),
      }),
      image2
    ),
  ]
}
