import React from "react"
import useCssUtil from "@hooks/use-css-util"
import { Container, Grid, Typography } from "@material-ui/core"
import { FormattedMessage } from "gatsby-plugin-intl"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import useImageByName from "@hooks/use-image-by-name"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    position: "relative",
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    backgroundImage: "linear-gradient(180deg, #F1EDE4 0%, #FFFDF9 100%)",
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/images/pattern-bg-reverse.svg)",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  innerContainer: {
    position: "relative",
    zIndex: 1,
  },
  largeText: {
    fontSize: "18px",
  },
  decryptImg: {
    maxWidth: 397,
    maxHeight: 478,
    width: "100%",
  },
  decryptImgContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
}))

export default function DecryptSection() {
  const classes = useCss()
  const cssUtil = useCssUtil()
  const decryptImg = useImageByName("decrypt-graphic.png")

  return (
    <>
      <section className={classes.container} id="total-encryption-standard">
        <Container className={classes.innerContainer} maxWidth="lg">
          <Grid alignItems="center" container spacing={5}>
            <Grid item xs={12} md={6}>
              <div className={classes.decryptImgContainer}>
                <img className={classes.decryptImg} src={decryptImg?.src} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className={cssUtil.mb15} variant="h2">
                <FormattedMessage
                  defaultMessage="Total Encryption Standard"
                  id="components.decryptSection.title"
                />
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                className={classes.largeText}
              >
                <FormattedMessage
                  defaultMessage="Your Totenpass can be totally encrypted and protected by a passhphrase. Using a combination of modern and peer-reviewed key-derivation and encryption algorithms (Argon2id and XChaCha20-Poly1305), you can input any plain text such as a password list, a mnemonic seed or a private key, or up to 2 KB of binary data and have it safely stored onto your Totenpass"
                  id="components.decryptSection.description"
                  values={{
                    0: (
                      <a
                        href="https://github.com/totenpass"
                        target="_blank"
                        rel="noopener"
                        className={cssUtil.goldText}
                      >
                        GitHub
                      </a>
                    ),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}
