import { useLocation } from "@reach/router"
import { useEffect, useState } from "react"
import { isnullOrUndefined, predicate } from "tiinvo"
import { usemount } from "./use-mount"

const includessafe = (needle: string) => (arg: string | null | undefined) => {
  if (isnullOrUndefined(arg)) {
    return false
  }
  return arg.includes(needle)
}

const isdevmode = predicate.or(
  includessafe("localhost"),
  includessafe("test.totenpass.com")
)

export const useAppURL = () => {
  const location = useLocation()
  const host = location.host
  const [url, seturl] = useState(host)
  const didmount = usemount()

  useEffect(() => {
    if (didmount) {
      seturl(window.location.href)
    }
  }, [didmount])

  if (isdevmode(url)) {
    return `https://app-test.totenpass.com`
  } else {
    return `https://app.totenpass.com`
  }
}
