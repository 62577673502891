import * as querystring from "@components/util/querystring"
import { useAppURL } from "@hooks/use-app-url"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import * as sdk from "@sdk/referral"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC, useEffect, useState } from "react"
import { either, FnNullary, isstring } from "tiinvo"

export interface ReferralDialogProps {
  onConfirm?: FnNullary<any>
  search: string
}

type Deserialized = Record<"r", string>

export const ReferralDialog: FC<ReferralDialogProps> = ({ search }) => {
  const deserialized = querystring.deserialize<Deserialized>(search)
  const appurl = useAppURL()
  const [open, setopen] = useState(false)
  const handleclose = () => {
    setopen(false)
  }

  useEffect(() => {
    if (deserialized.r) {
      sdk.referral(deserialized.r).then((ei) => {
        setopen(either.isRight(ei))
      })
    }
  }, [deserialized.r])

  if (!open) {
    return null
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={handleclose}>
        <DialogTitle>
          <FormattedMessage
            defaultMessage="Free shipping"
            id="components.ReferralDialog.title"
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            defaultMessage="For your {0} Totenpass only, all shipping costs are free!"
            id="components.ReferralDialog.content000"
            values={{
              "0": (
                <strong style={{ fontWeight: 900 }}>
                  <FormattedMessage
                    defaultMessage="first"
                    id="components.ReferralDialog.content000.first"
                  />
                </strong>
              ),
            }}
          />
        </DialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button onClick={handleclose}>
            <FormattedMessage
              id="components.ReferralDialog.close"
              defaultMessage="Close"
            />
          </Button>
          <a href={appurl} style={{ textDecoration: `none` }}>
            <Button color="primary" variant="contained">
              <FormattedMessage
                id="components.ReferralDialog.cancel"
                defaultMessage="Create Your Totenpass"
              />
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </>
  )
}
