import useCssUtil from "@hooks/use-css-util"
import { Container, Grid, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Feat6Icon from "@svg/demagnetization-icon.svg"
import Feat3Icon from "@svg/emp-icon.svg"
import Feat1Icon from "@svg/fire-icon.svg"
import Feat5Icon from "@svg/salt-water-icon.svg"
import Feat4Icon from "@svg/space-icon.svg"
import Feat2Icon from "@svg/sun-icon.svg"
import DesktopGraph from "@svg/tp-vs-other-tech--lg.svg"
import MobileGraph from "@svg/tp-vs-other-tech--sm.svg"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: "-1",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: "url(/images/pattern-bg.svg)",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingMdTop,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  title: {
    color: theme.custom.lightHeadingTextColor,
    marginBottom: "64px",
  },
  desktopGraph: {
    display: "none",
    width: "100%",
    height: "auto",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  mobileGraph: {
    display: "block",
    width: "100%",
    maxWidth: "450px",
    height: "auto",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  featContainer: {
    paddingTop: theme.custom.sectionSpacingFluid,
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingMdTop,
    },
  },
  featDescription: {
    color: "#D9D9D9",
  },
  gridItem: {
    justifyContent: "center",
    textAlign: "center",
  },
  featTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
}))

export default function Hero() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.container}>
      <Container maxWidth="md">
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={classes.title}
        >
          <FormattedMessage
            defaultMessage="Totenpass vs. Other Storage Drive Technology"
            id="components.TPvs.title"
          />
        </Typography>
      </Container>

      <Container maxWidth="md">
        <DesktopGraph className={classes.desktopGraph} />
        <MobileGraph className={classes.mobileGraph} />
      </Container>

      <Container maxWidth="md" className={classes.featContainer}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h4" className={cssUtil.mb12}>
              <FormattedMessage
                defaultMessage="Totenpass vs. Other Means of Storage"
                id="components.TPvs.featureTitle"
              />
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className={classes.featDescription}
            >
              <FormattedMessage
                defaultMessage="Have you ever uploaded your photographs or documents to a hard drive? Most people don’t realize that, within 3-5 years, you have to do this entire process over again or else your precious bits of data may suffer from {0}. This implicit obsolescence in the material science that undergirds all mainstream means of storing information means that your data must be continually preserved in new devices until, eventually, it is lost or destroyed. In addition to this risk is the risk of natural disasters. The Totenpass is immune to all natural and unnatural risks, from an EMP, to a flood, earthquake or fire. Like the great, golden relics of long lost kings which archaeologists discover every day, your Totenpass will endure for future generations to behold."
                id="components.TPvs.featDescription"
                values={{
                  0: (
                    <a
                      href="https://en.wikipedia.org/wiki/Data_degradation"
                      target="_blank"
                      rel="noopener"
                      className={cssUtil.goldText}
                    >
                      bitrot
                    </a>
                  ),
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={4} className={classes.gridItem}>
                <Feat1Icon className={cssUtil.mb20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.featTitle}
                >
                  <FormattedMessage
                    defaultMessage="Fire > 300°F"
                    id="components.TPvs.feat1Title"
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.gridItem}>
                <Feat2Icon className={cssUtil.mb20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.featTitle}
                >
                  <FormattedMessage
                    defaultMessage="Sun"
                    id="components.TPvs.feat2Title"
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.gridItem}>
                <Feat3Icon className={cssUtil.mb20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.featTitle}
                >
                  <FormattedMessage
                    defaultMessage="EMP"
                    id="components.TPvs.feat3Title"
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.gridItem}>
                <Feat4Icon className={cssUtil.mb20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.featTitle}
                >
                  <FormattedMessage
                    defaultMessage="Space"
                    id="components.TPvs.feat4Title"
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.gridItem}>
                <Feat5Icon className={cssUtil.mb20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.featTitle}
                >
                  <FormattedMessage
                    defaultMessage="Salt Water"
                    id="components.TPvs.feat5Title"
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.gridItem}>
                <Feat6Icon className={cssUtil.mb20} />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.featTitle}
                >
                  <FormattedMessage
                    defaultMessage="Demagnetization"
                    id="components.TPvs.feat6Title"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
