import useImageByName from "@hooks/use-image-by-name"
import { IFluidImage } from "@hooks/use-images"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { motion } from "framer-motion"
import { defineMessages, MessageDescriptor, useIntl } from "gatsby-plugin-intl"
import React, { FC, useState } from "react"
import { useInterval } from "react-use"

export interface HeroCarouselProps {}

type SlideType = "horizontal" | "vertical"

interface SlideBase {
  image: IFluidImage
  label: MessageDescriptor
  type: SlideType
}

interface Slide extends SlideBase {
  next: Slide
  prev: Slide
}

//#region i18n

const messages = defineMessages({
  h1: {
    id: "herocarousel.h1",
    defaultMessage: "Botticelli - the birth of Venus",
  },
  h3: { id: "herocarousel.h3", defaultMessage: "Family portrait" },
  h4: {
    id: "herocarousel.h4",
    defaultMessage: "Lewis Carrol - Alice in wonderland",
  },
  h5: { id: "herocarousel.h5", defaultMessage: "Bitcoin Wallet" },
  v1: { id: "herocarousel.v1", defaultMessage: "Da Vinci - the Gioconda" },
  v2: { id: "herocarousel.v2", defaultMessage: "Wedding memory" },
})

//#endregion

const createSlideBase = (
  image: IFluidImage,
  type: SlideType,
  label: MessageDescriptor
): SlideBase => ({
  image,
  type,
  label,
})

const useimages = () => [
  createSlideBase(
    useImageByName("tp-hero-h-1.png")!,
    "horizontal",
    messages.h1
  ),
  createSlideBase(
    useImageByName("tp-hero-h-3.png")!,
    "horizontal",
    messages.h3
  ),
  createSlideBase(
    useImageByName("tp-hero-h-4.png")!,
    "horizontal",
    messages.h4
  ),
  createSlideBase(
    useImageByName("tp-hero-h-5.png")!,
    "horizontal",
    messages.h5
  ),
  createSlideBase(useImageByName("tp-hero-v-1.png")!, "vertical", messages.v1),
  createSlideBase(useImageByName("tp-hero-v-2.png")!, "vertical", messages.v2),
]

const useslides = () => {
  const slides = useimages() as Slide[]

  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i]

    slide.next = i === slides.length - 1 ? slides[0] : slides[i + 1]
    slide.prev = i === 0 ? slides[slides.length - 1] : slides[i - 1]
  }

  return slides
}

const usecss = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: 370,
    [theme.breakpoints.up("sm")]: {
      height: 350,
    },
  },
  image: {
    objectFit: `contain`,
    height: `100%`,
    width: `100%`,
    top: 0,
    left: 0,
    position: "absolute",
  },
  imagecontainer: {
    margin: "0 auto",
    transformOrigin: "center",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  imagecontainerhorizontal: {
    width: "100%",
    maxWidth: "100%",
    top: "12.5%",
    [theme.breakpoints.up("sm")]: {
      width: 560,
      top: 0,
    },
  },
  imagecontainervertical: {
    height: 370,
    width: "auto",
    top: "4%",
  },
  label: {
    left: 0,
    position: "absolute",
    textAlign: "center",
    top: -40,
    fontSize: 16,
    userSelect: "none",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      top: -20,
    },
  },
  labelVertical: {
    top: -40,
  },
}))

const sizes = {
  horizontal: {
    height: 327,
    width: 522,
  },
  vertical: {
    height: 370,
    width: 232,
  },
}

export const HeroCarousel: FC<HeroCarouselProps> = ({}) => {
  const classes = usecss()
  const [current, setcurrent] = useState(0)
  const slides = useslides()
  const { formatMessage } = useIntl()
  const max = slides.length - 1
  const transitionduration = 4000
  const getclassname = (slide: Slide) =>
    clsx(
      classes.imagecontainer,
      slide.type === "horizontal" && classes.imagecontainerhorizontal,
      slide.type === "vertical" && classes.imagecontainervertical
    )

  useInterval(() => {
    setcurrent(current === max ? 0 : current + 1)
  }, transitionduration)

  return (
    <motion.div className={classes.container} layoutTransition>
      {slides.map((slide, index) => (
        <motion.div
          animate={{
            opacity: index === current ? 1 : 0,
            width: sizes[slide.type].width,
          }}
          className={getclassname(slide)}
          key={index}
          initial={false}
        >
          <motion.img
            className={classes.image}
            src={slide.image.src}
            srcSet={slide.image.srcSet}
          />
          <motion.div
            className={clsx(
              classes.label,
              slide.type === "vertical" && classes.labelVertical
            )}
          >
            {formatMessage(slide.label)}
          </motion.div>
        </motion.div>
      ))}
    </motion.div>
  )
}
