import useCssUtil from "@hooks/use-css-util"
import useImageByName from "@hooks/use-image-by-name"
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import KeyFeaturesItem from "./KeyFeaturesItem"
import useKeyFeatures from "@hooks/use-key-features"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    position: "relative",
    paddingTop: theme.custom.sectionSpacingFluid,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "calc(100% - 15vw)",
      backgroundImage: "linear-gradient(180deg, #FFFDF9 0%, #F1EDE4 100%)",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      marginBottom: theme.custom.sectionSpacingLg,
      "&::after": {
        height: "100%",
      },
    },
  },
  bottomBlock: {
    position: "relative",
    zIndex: 1,
    marginTop: theme.custom.sectionSpacingFluid,
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginTop: theme.custom.sectionSpacingSm,
    },
  },
  tpBuilderScreenshotContainer: {
    position: "relative",
    zIndex: 1,
    height: "auto",
    [theme.breakpoints.up("md")]: {
      height: 428,
    },
  },
  tpBuilderScreenshot: {
    display: "flex",
    width: "100%",
    maxWidth: 960,
    margin: "0 auto",
  },
}))

export default function KeyFeatures() {
  const classes = useCss()
  const cssUtil = useCssUtil()
  const tpbuilder = useImageByName("tp-builder.png")
  const keyFeatures = useKeyFeatures()

  return (
    <section className={classes.container}>
      {keyFeatures.map((feature, index) => (
        <KeyFeaturesItem
          key={index}
          imageDirection={index % 2 === 0 ? "left" : "right"}
          summary={feature.summary}
          title={feature.title}
          image={feature.image}
        />
      ))}

      <Container maxWidth="sm" className={classes.bottomBlock}>
        <Typography align="center" variant="h2" className={cssUtil.mb20}>
          <FormattedMessage
            defaultMessage="Easy to Use Totenpass Builder"
            id="components.KeyFeature.f4Title"
          />
        </Typography>
      </Container>

      <Container maxWidth="lg" className={classes.tpBuilderScreenshotContainer}>
        <img
          src={tpbuilder?.src}
          srcSet={tpbuilder?.srcSet}
          className={classes.tpBuilderScreenshot}
        />
      </Container>
    </section>
  )
}
