import { useAppURL } from "@hooks/use-app-url"
import { usemount } from "@hooks/use-mount"
import { Button } from "@material-ui/core"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC, useEffect, useRef } from "react"

export interface GetStartedButtonProps {}

export const GetStartedButton: FC<GetStartedButtonProps> = ({}) => {
  const appurl = useAppURL()
  const aref = useRef<HTMLAnchorElement | null>(null)

  useEffect(() => {
    if (aref && aref.current) {
      aref.current.href = appurl
    }
  }, [usemount()])

  return (
    <a
      ref={aref}
      style={{ textDecoration: "none" }}
      href={appurl}
      target="_blank"
    >
      <Button color="primary" variant="contained">
        <FormattedMessage
          defaultMessage="Get Started"
          id="components.GetStartedButton"
        />
      </Button>
    </a>
  )
}
